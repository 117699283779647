














































































































import { Component, Vue } from "vue-property-decorator";
import { Page } from "@/models/Page";
import {
	PAGE_REQUEST,
	PAGE_UPDATE,
	PAGE_CREATE,
	PAGE_DELETE,
	PAGE_UPDATED,
	PAGE_LOADED,
} from "@/store/actions/page";

@Component({})
export default class PageTable extends Vue {
	private table: Page[] = [];
	private loading: boolean = false;
	// deleting
	private itemToDelete: any = {};
  private deleteDialog: boolean = false;
	private deleteSnackbar: boolean = false;
  private deleteDisabled: boolean = false;
	//private totalRows = 0;
	//Initialise methods
	private initIfReady() {
		//return true if started to init self.
		let needReload = [] as boolean[];
		needReload.push(
			this.needReload(
				this.$store.getters.PAGE_TABLE,
				this.$store.getters.PAGE_LOADED
			)
		);
		for (let i = 0; i < needReload.length; i++) {
			if (needReload[i] == true) {
				return false;
			}
		}
		console.log("TRUE INIT SELF");
		this.initSelf();
		return true;
	}
	private needReload(table, updated) {
		/*
		data is a table loading from request.
		updated is flag that is false when table has old data and need to be reloading.
		return true if need reload.
		*/
		if (table == null || !updated) return true;
		return false;
	}
	private tryToInitSelf() {
		this.loading = true;
		//const data = this.getParams();
		if (this.initIfReady()) return;

		if (
			this.needReload(
				this.$store.getters.PAGE_TABLE,
				this.$store.getters.PAGE_LOADED
			)
		) {
			this.$store.dispatch(PAGE_REQUEST).then(() => {
				this.initIfReady();
			});
		}
	}
	private initSelf() {
		console.log("INIT SELF");
		this.table = this.$store.getters.PAGE_TABLE.data;
		let table = this.table;

		// for(let i = 1; i < table.length + 1; i++) {
		// 	if(i % 3) {
		// 		this.totalRows++;
		// 	}
		// }
		this.loading = false;
		console.log("loading");
	}
	//Methods
	private isDeleting(id) {
    if (this.itemToDelete.id == id) return true;
    return false;
  }
	private toCreate() {
		this.$router.push({ name: "PagesCreate" });
	}
	private toEdit(item) {
		console.log("item: ", item);
		const parsed = JSON.stringify(item);
		localStorage.setItem("pageToEdit", parsed);
		this.$router.push({ name: "PagesUpdate", params: { id: item.id } });
	}
	private deleteItem(item: any, confirmation:boolean) {
		this.itemToDelete = item;
    if(confirmation)
    {
			this.deleteDialog = true;
		}
    else
		{
      console.log(item);
			console.log("Delete!");
			this.$store.dispatch(PAGE_DELETE, item.id).then(() => {
				this.tryToInitSelf();
				this.deleteSnackbar = true;
				this.itemToDelete = new Template();
				this.deleteDisabled = false;
		});
		this.deleteDialog = false;
    }
	}
	private getSrc(address) {
		return process.env.VUE_APP_API_ENDPOINT + "/" + address;
	}
	//Hooks
	private mounted() {
		this.tryToInitSelf();
	}
}
